<template lang="pug">
  v-container(fluid)
    v-row.mb-2.mr-1(justify='end')
      v-btn(
        color='accent',
        elevation='0',
        @click='NewAgency',
        height='40',
        large
      ) Создать агенство
      v-btn.ml-3(
        :loading='isLoading',
        elevation='0',
        color='accent',
        @click='agency_data',
        small,
        height='40',
      )
        v-icon mdi-refresh
    v-row
      v-col.pa-0.ma-0
        v-data-table.elevation-0(
          :headers='headers',
          :loading='isLoading',
          loading-text='Данные загружаются, ожидайте',
          :items='getAgencies',
          hide-default-footer
        )
          template(v-slot:progress)
            v-progress-linear(color="accent" indeterminate)
          template(v-slot:no-data)
            .text-center.no-data_background.pa-10
              v-icon.mt-14(size='48', color='#BDBDBD') mdi-search-web
              h2.mt-4 Нет данных
              h3.mt-2 Попробуйте выбрать другие значения или создайте агентство
          template(v-slot:item.name='{ item }')
            v-btn.mt-3.mb-3.v-btn--table-link.v-btn--table-link--colored.pl-2(
              @click='GoToAgency(item)'
              outlined,
              color='accent'
              elevation='0'
            ) {{ item.name }}
          template(v-slot:item.check='{ item }')
            v-btn-toggle(v-if='!(EditBudgetAgencyID === item.id)')
              tooltip(text='Пополнение баланса агентства')
                v-btn.mr-1(
                  small,
                  width='115',
                  height='32',
                  color='accent',
                  elevation='0',
                  @click='EditBalance(item.id, "plus")',
                ) Пополнить
              tooltip(text='Снять сумму со счета агентства')
                v-btn(
                  small,
                  color='accent',
                  height='32',
                  width='85'
                  elevation='0',
                  @click='EditBalance(item.id, "minus")',
                ) Снять
            v-row(v-if='EditBudgetAgencyID === item.id')
              v-text-field.ml-3.money-field(
                dense,
                outlined,
                hide-details,
                type='number',
                min='0',
                v-model='MoneyCount',
              )
              v-btn.ml-2(
                width='44',
                height='36',
                x-small,
                color='accent'
                @click='ApplyBalance(item)',
                :class='{ loading: TransferingMoney }'
              )
                v-icon(small) mdi-check
              v-btn.ml-2(
                color='accent',
                width='44',
                height='36',
                x-small,
                @click='EditBudgetAgencyID = -1'
              )
                v-icon(x-small) mdi-block-helper
          template(v-slot:header.actions='{ item }')
            export(
              filename='Сводная таблица',
              :headers='["Агентство", "Баланс", "Наценка"]',
              :data='Export()'
            )
          template(v-slot:item.actions='{ item }')
            div(style='display: flex; align-items: right; justify-content: right')
              tooltip(text='Редактировать данные агентства')
                v-btn(
                  color='grey darken-4',
                  width='32',
                  height='33',
                  icon,
                  elevation='2',
                  @click='Agency(item)'
                )
                  v-icon(small) mdi-lead-pencil
              tooltip(text='Посмотреть транзакции')
                v-btn.ml-2(
                  color='grey darken-4'
                  width='32',
                  height='33',
                  icon,
                  elevation='2',
                  @click='transactionDialog = true; currentAgency = item'
                )
                  v-icon(small) mdi-menu
    v-dialog(v-model='AgencyDialogShow', width='640')
      v-card(elevation='0')
        .dialog-title_center.text-center.pt-4 {{CreateNewAgency ? 'Новое агентство' : 'Редактирование агентства'}}
        v-card-text
          v-tabs.mt-6(slider-color='accent', color='#303133', v-model="tab")
            v-tab.mt-2(v-for="item in items" :key="item") {{item}}
          v-tabs-items(v-model="tab")
            v-divider.ml-3
            v-tab-item
              v-row
                v-col(cols='12')
                  v-card-subtitle.v-card-subtitle.pb-2 Название
                  v-text-field.ml-4.mt-0(
                    height='40',
                    hide-details,
                    outlined,
                    dense,
                    type='text',
                    placeholder='Название агентства',
                    v-model='AgencyData.name'
                  )
            v-tab-item
              v-row
                v-col(cols='12')
                  v-card-subtitle.v-card-subtitle.pb-1.mb-0 Процент наценки
                  span.pl-4 Укажите значение в числовом коэффициенте. Например 0,1 = 10%
                  v-text-field.ml-4.mt-1(
                    height='40',
                    dense,
                    outlined,
                    type='number',
                    min=0,
                    max=1,
                    oninput='if(Number(this.value) > Number(this.max)) this.value = this.max',
                    placeholder='Наценка для компании',
                    v-model='AgencyData.charge'
                  )
            v-tab-item
              v-row
                v-col.mt-2.pt-0.pb-0.mb-0(cols='12', v-if='CreateNewAgency')
                  v-card-subtitle.v-card-subtitle.pb-2.mb-0 Логин
                  v-text-field.ml-4.mt-0(
                    height='40',
                    outlined,
                    dense,
                    v-model='AgencyData.login',
                    type='text',
                    placeholder='Логин'
                  )
                v-col.mt-2.pt-0.pb-0.mb-0(cols='12')
                  v-card-subtitle.v-card-subtitle.pb-2.mb-0 ИНН
                  v-text-field.ml-4.mt-0(
                    height='40',
                    outlined,
                    dense,
                    v-model='AgencyData.inn',
                    type='text',
                    placeholder='ИНН',
                    v-mask="'############'"
                  )
                v-col.mt-0.pt-0.pb-0.mb-0(cols='12')
                  v-card-subtitle.v-card-subtitle.mt-0.pt-0.pb-2.mb-0 ОГРН
                  v-text-field.ml-4.mt-0(
                    height='40',
                    outlined,
                    dense,
                    v-model='AgencyData.ogrn',
                    type='text',
                    placeholder='ОГРН',
                    v-mask="'############'"
                  )
                v-col.mt-0.pt-0.pb-0.mb-0(cols='12')
                  v-card-subtitle.v-card-subtitle.mt-0.pt-0.pb-2.mb-0 Контактное лицо
                  v-text-field.ml-4.mt-0(
                    height='40',
                    outlined,
                    dense,
                    v-model='AgencyData.person',
                    type='text',
                    placeholder='Контактное лицо'
                  )
                v-col.mt-0.pt-0.pb-0.mb-0(cols='12')
                  v-card-subtitle.v-card-subtitle.mt-0.pt-0.pb-2.mb-0 Телефон
                  v-text-field.ml-4.mt-0(
                    height='40',
                    outlined,
                    dense,
                    v-model='AgencyData.phone',
                    type='text',
                    placeholder='Телефон'
                  )
                v-col.mt-0.pt-0.pb-0.mb-0(cols='12')
                  v-card-subtitle.v-card-subtitle.mt-0.pt-0.pb-2.mb-0 Email
                  v-text-field.ml-4.mt-0(
                    height='40',
                    outlined,
                    dense,
                    v-model='AgencyData.email',
                    type='text',
                    placeholder='Почта контактного лица'
                  )
            v-card-actions.mt-1
              v-spacer
              v-btn(
                width='95',
                height='40',
                elevation='0',
                color='accent',
                v-if='CreateNewAgency',
                @click='CreateAgency()'
              ) Создать
              v-btn(
                width='100'
                height='40',
                elevation='0',
                color='accent',
                v-if='!CreateNewAgency',
                @click='EditAgency',
                v-bind:class='{ loading: isLoading }'
              ) Изменить
              v-btn(
                width='95',
                height='40',
                @click='AgencyDialogShow = false',
                outlined,
                text,
                color='#616161',
                elevation='0'
              ) Отмена
    v-dialog(v-model='transactionDialog', width='1100')
      transactions(
        v-if='transactionDialog',
        :title='`Транзакции агентства "${currentAgency.name}"`'
        :transactionsAccounts='getAllAccounts',
        :interfaceName='currentAgency.name',
        :value='currentAgency',
        interfaceName='root'
      )

</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Tooltip from '@/components/tooltip'
import ExportTable from '@/components/export_table'
import transactions from '@/components/transactions'
import loading_mixin from '@/mixins/loading_mixin'
import {ACCOUNTS, AGENCY_DATA, AGENCY_TRANSFER_MONEY, CREATE_EDIT_AGENCY} from '@/store/const/root'
import {NEW_MODE} from '@/store/const/auth'

export default {
  name: 'agencies',
  mixins: [loading_mixin],
  components: {
    Tooltip,
    transactions,
    export: ExportTable
  },
  data() {
    return {
      headers: [
        {text: 'Агентство', value: 'name', width: 500},
        {text: 'Баланс', value: 'balance'},
        {text: 'Наценка', value: 'charge'},
        {text: 'Счет', value: 'check', sortable: false},
        {value: 'actions', sortable: false}
      ],
      tab: null,
      items: ['Основное', 'Настройки', 'Дополнительно'],
      CreateNewAgency: false,
      transactionDialog: false,
      currentAgency: {},
      AgencyDialogShow: false,
      EditBudgetAgencyID: -1,
      EditBudgetAction: '',
      MoneyCount: 0,
      TransferingMoney: false,
      AgencyData: {},
    }
  },
  async created() {
    await this.accounts({agency: this.currentAgency.id})
  },
  computed: {
    ...mapGetters('Root', ['getAllAccounts', 'getAgencies']),
    ...mapGetters('Auth', ['getSession']),
    ...mapGetters(['loading']),
  },
  mounted() {
    this.setLoadingActions()
  },
  methods: {
    ...mapActions('Root', {
      accounts: ACCOUNTS,
      create_edit_agency: CREATE_EDIT_AGENCY,
      agency_data: AGENCY_DATA,
      agency_transfer_money: AGENCY_TRANSFER_MONEY,
    }),
    ...mapActions('Auth', {
      new_mode: NEW_MODE,
    }),
    setLoadingActions() {
      this.actions = [
        ACCOUNTS,
        CREATE_EDIT_AGENCY,
        AGENCY_DATA,
        NEW_MODE,
        AGENCY_TRANSFER_MONEY
      ]
    },
    Export() {
      return this.getAgencies.map((item) => {
        return [item.name, item.balance.toFixed(2), item.charge]
      })
    },
    GoToAgency(row) {
      let params = {
        id: row.id,
        type: 'agency',
        session_type: 'root',
      }
      this.new_mode(params)
      if (this.isError(NEW_MODE)) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Не удалось совершить переход, попробуйте повторить позже',
        })
      } else {
        this.$router.push({path: '/agency/'})
      }
    },
    Agency(row) {
      this.CreateNewAgency = false
      this.AgencyDialogShow = true
      this.AgencyData = Object.assign({}, row)
    },
    NewAgency() {
      this.CreateNewAgency = true
      this.AgencyDialogShow = true
      this.AgencyData = {}
    },
    async CreateAgency() {
      await this.create_edit_agency(this.AgencyData)
      if (this.isError(CREATE_EDIT_AGENCY)) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Не удалось создать агенство',
        })
      }
      this.AgencyDialogShow = false
      await this.agency_data()
    },
    async EditAgency() {
      await this.create_edit_agency(this.AgencyData)
      if (this.isError(CREATE_EDIT_AGENCY)) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Ошибка при редактировании агенства',
        })
      }
      this.AgencyDialogShow = false
      this.agency_data()
    },
    EditBalance(index, action) {
      this.EditBudgetAgencyID = index
      this.EditBudgetAction = action
    },
    async ApplyBalance(row) {
      if (!this.TransferingMoney) {
        this.TransferingMoney = true
        let money = this.MoneyCount
        let message = 'Балланс пополнен'
        if (this.EditBudgetAction === 'minus') {
          money = -money
          message = 'Операция выполнена'
        }
        await this.agency_transfer_money({
          id: row.id,
          money: money,
          session_type: 'root'
        })
        if (this.isError(AGENCY_TRANSFER_MONEY)) {
          this.$notify({
            type: 'error',
            title: 'Ошибка',
            text: 'Ошибка при выполнении операции',
          })
          return
        }
        await this.agency_data()
        this.$notify({
          type: 'success',
          title: 'Успешно',
          text: message,
        })
        this.TransferingMoney = false
        this.EditBudgetAgencyID = -1
        this.MoneyCount = 0
      }
    },
  }
}
</script>
<style lang="scss">
.v-data-table th {
  color: #909399 !important;
  font-size: 14px !important;
}

.v-card-subtitle {
  font-size: 16px;
  color: #606266;
}

.v-tab {
  text-transform: none !important;
}

.dialog-title_center {
  font-weight: 500;
  font-size: 24px;
  color: #303133;
}
</style>
