<template lang="pug">
  v-card(elevation='0')
    v-card-title {{ title }}
    v-card-text
      v-container.grid-list-md(fluid)
        v-flex.xs12.md4
          v-menu(
            ref='menu',
            v-model='menu',
            :close-on-content-click='false',
            transition='scale-transition',
            offset-y='',
            min-width='auto'
          )
            v-date-picker(
              header-color='accent',
              v-model="dates",
              show-adjacent-months,
              range,
              locale='ru-ru',
              first-day-of-week='1'
            )
            template(v-slot:activator='{ on, attrs }')
              v-text-field(
                v-model='transactionsPeriod',
                prepend-icon='mdi-calendar',
                readonly='',
                v-bind='attrs',
                v-on='on',
                dense,
                outlined,
                hide-details
              )
          v-btn-toggle.mt-6(
            v-model='filterParam',
            v-if='interfaceName === "root"',
            color='accent',
          )
            v-btn(value='agencies',) Агентство
            v-btn(value='accounts') Аккаунты
            v-btn(value='both') Агентство и аккаунты
        div
          v-data-table(
            v-if='filteredTransactions.length > 0',
            :headers='headers',
            :items='filteredTransactions',
            :loading='isLoading'
            loading-text='Данные загружаются, ожидайте',
            hide-default-footer
          )
            template(v-slot:header.account='{ header }', v-if='interfaceName === "root"')
              span {{ header.text }}
            template(
              v-slot:item.date,
              v-for='item in filteredTransactions.slice((page - 1) * 7, (page - 1) * 7 + 7)'
            ) {{ convertDate(item.time) }}
            template(
              v-slot:item.money='{ item }',
              v-for='item in filteredTransactions.slice((page - 1) * 7, (page - 1) * 7 + 7)'
            )
              v-chip.border__none(
                outlined,
                :color='getColorMoney(item.money)'
              ) {{ item.money }}
            template(
              v-slot:item.account='{ item }',
              v-for='item in filteredTransactions.slice((page - 1) * 7, (page - 1) * 7 + 7)'
              v-if='interfaceName === "root"'
            ) {{ accountNameByID(item.account) }}
          .text-center.no-data_background.pa-10.mt-4(v-if='!isLoading && filteredTransactions.length === 0')
            v-icon.mt-14(size='48', color='#BDBDBD') mdi-search-web
            h2.mt-4 Нет транзакций за данный период
            h3.mt-2 Выберите другие даты чтобы увидеть результат
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {CLEAR_PREV_DATA, GET_TRANSACTIONS, SET_ENTITY_ID, SET_INTERFACE_NAME} from '@/store/const/finance'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    interfaceName: {
      type: String,
      required: true,
    },
    value: {
      type: [Object, String],
      required: true,
    },
    transactionsAccounts: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      headers: [
        {text: 'Дата', value: 'date', align: 'center', sortable: false},
        {text: 'Сумма', value: 'money', align: 'center', sortable: false},
        {text: 'Аккаунт', value: 'account', align: 'center', sortable: false}
      ],
      menu: false,
      filterParam: 'accounts',
      page: 1,
      dates: []
    }
  },
  watch: {
    dates() {
      if (this.dates.length < 2) {
        return
      }
      this.get_transactions({
        date: [this.$moment(this.dates[0]).valueOf(), this.$moment(this.dates[1]).set({
          hour: 23,
          minute: 59,
          second: 59
        }).valueOf()]
      })
    }
  },
  computed: {
    ...mapGetters(['loading']),
    ...mapGetters('Finance', ['getDates', 'getFilteredTransactions']),

    isLoading() {
      return this.loading(GET_TRANSACTIONS) === 'loading'
    },
    id() {
      return this.value ? this.value.id : 'err'
    },
    filteredTransactions() {
      if (this.filterParam === 'accounts') {
        return this.getFilteredTransactions.filter(
          (transaction) => transaction.account !== '000000000000000000000000',
        )
      } else if (this.filterParam === 'agencies') {
        return this.getFilteredTransactions.filter(
          (transaction) => transaction.account === '000000000000000000000000',
        )
      }
      return this.getFilteredTransactions || []
    },
    transactionsPeriod() {
      let total = ''
      if (!this.dates.length) {
        return total
      }
      total += this.dates[0] < this.dates[1]
        ? this.$moment(this.dates[0]).format('DD.MM.YYYY')
        : this.$moment(this.dates[1]).format('DD.MM.YYYY')
      total += ' - '
      total += this.dates[0] < this.dates[1]
        ? this.$moment(this.dates[1]).format('DD.MM.YYYY')
        : this.$moment(this.dates[0]).format('DD.MM.YYYY')
      return total
    }
  },
  created() {
    if (this.interfaceName === 'root') {
      this.filterParam = 'agencies'
    }
    this.set_entity_id(this.id)
    this.set_interface_name(this.interfaceName)
    this.clear_prev_data()
    this.dates[0] = this.$moment().startOf('month').format()
    this.dates[1] = this.$moment().endOf('month').format()
    this.get_transactions({
      date: [this.$moment(this.dates[0]).valueOf(), this.$moment(this.dates[1]).valueOf()]
    })
  },
  methods: {
    ...mapMutations('Finance', {
      set_entity_id: SET_ENTITY_ID,
      set_interface_name: SET_INTERFACE_NAME,
      clear_prev_data: CLEAR_PREV_DATA
    }),
    ...mapActions('Finance', {
      get_transactions: GET_TRANSACTIONS,
    }),
    getColorMoney(money) {
      if (money > 0) return '#32b643'
      else if (money < 0) return '#e85600'
    },
    convertDate(val) {
      let d = new Date(val * 1000)
      let date = d.getDate()
      if (date < 10) date = '0' + date
      let month = d.getMonth() + 1
      let year = d.getFullYear()
      return date + '.' + month + '.' + year
    },
    accountNameByID(id) {
      let account = this.transactionsAccounts.find((element) => {
        return element.id === id
      })
      if (account === undefined) return 'Агентство'
      return account.name
    },
  },
}
</script>

<style lang="scss">
.text-success {
  color: #32b643
}

.text-error {
  color: #e85600
}

.border__none {
  border: none !important;
  font-weight: bolder;
}
</style>
