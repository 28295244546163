var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.title))]),_c('v-card-text',[_c('v-container',{staticClass:"grid-list-md",attrs:{"fluid":""}},[_c('v-flex',{staticClass:"xs12 md4"},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.transactionsPeriod),callback:function ($$v) {_vm.transactionsPeriod=$$v},expression:"transactionsPeriod"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"header-color":"accent","show-adjacent-months":"","range":"","locale":"ru-ru","first-day-of-week":"1"},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),(_vm.interfaceName === "root")?_c('v-btn-toggle',{staticClass:"mt-6",attrs:{"color":"accent"},model:{value:(_vm.filterParam),callback:function ($$v) {_vm.filterParam=$$v},expression:"filterParam"}},[_c('v-btn',{attrs:{"value":"agencies"}},[_vm._v("Агентство")]),_c('v-btn',{attrs:{"value":"accounts"}},[_vm._v("Аккаунты")]),_c('v-btn',{attrs:{"value":"both"}},[_vm._v("Агентство и аккаунты")])],1):_vm._e()],1),_c('div',[(_vm.filteredTransactions.length > 0)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredTransactions,"loading":_vm.isLoading,"loading-text":"Данные загружаются, ожидайте","hide-default-footer":""},scopedSlots:_vm._u([(_vm.interfaceName === "root")?{key:"header.account",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(header.text))])]}}:null,_vm._l((_vm.filteredTransactions.slice((_vm.page - 1) * 7, (_vm.page - 1) * 7 + 7)),function(item){return {key:"item.date",fn:function(){return [_vm._v(_vm._s(_vm.convertDate(item.time)))]},proxy:true}}),_vm._l((_vm.filteredTransactions.slice((_vm.page - 1) * 7, (_vm.page - 1) * 7 + 7)),function(item){return {key:"item.money",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"border__none",attrs:{"outlined":"","color":_vm.getColorMoney(item.money)}},[_vm._v(_vm._s(item.money))])]}}}),(_vm.interfaceName === "root")?_vm._l((_vm.filteredTransactions.slice((_vm.page - 1) * 7, (_vm.page - 1) * 7 + 7)),function(item){return {key:"item.account",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.accountNameByID(item.account)))]}}}):null],null,true)}):_vm._e(),(!_vm.isLoading && _vm.filteredTransactions.length === 0)?_c('div',{staticClass:"text-center no-data_background pa-10 mt-4"},[_c('v-icon',{staticClass:"mt-14",attrs:{"size":"48","color":"#BDBDBD"}},[_vm._v("mdi-search-web")]),_c('h2',{staticClass:"mt-4"},[_vm._v("Нет транзакций за данный период")]),_c('h3',{staticClass:"mt-2"},[_vm._v("Выберите другие даты чтобы увидеть результат")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }