<template lang="pug">
agencies
</template>
<script>
import agencies from '@/components/agencies';

export default {
	components: {
		agencies,
	},
};
</script>
